import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./app/App";
import './styles/styles.scss';
import store from './logic/index'
import {Provider} from "react-redux";

require("bootstrap/dist/js/bootstrap.min");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
