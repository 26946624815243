import React, {forwardRef, HTMLInputTypeAttribute, ReactNode, useEffect} from "react";
import MobileDetect from "mobile-detect";
import {Dispatch} from "redux";
import {setDataField} from "../logic/reducer";
import {SUBMIT_KEY} from "../utils/constants";

type InputMode = 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'

export interface InputOptions {
  placeholder?: string,
  inputMode?: InputMode,
  className?: string
  disabled?: boolean,
  customComponent?: ReactNode
  text?: string
  addToLabel?: ReactNode|undefined,
  hideLabel?: boolean
  optional?: boolean
}

const md = new MobileDetect(window.navigator.userAgent);
export const isMobile = !!(md.mobile() || md.phone());

export function renderTitle(t: string, additional?: ReactNode|undefined) {
  return <b className="d-block mt-4 mb-2">{t}{additional}</b>;
}

export function renderInput(
    id: string,
    type: HTMLInputTypeAttribute | null,
    label: string,
    val: any,
    dispatch: Dispatch<any>,
    options?: InputOptions
) {
  return (<div className={`form-group ${options?.className || ""}`}>
    { !options?.hideLabel && (<label htmlFor={id} className="">{label}{options?.optional ? "" : "*"}{options?.addToLabel||null}</label>) }
    {
        !options?.customComponent && (<input
            id={id}
            type={type || undefined}
            step={type === "number" ? 0.01 : undefined}
            min={type === "number" ? 0 : undefined}
            className="form-control"
            placeholder={options?.placeholder}
            value={val}
            inputMode={options?.inputMode}
            onChange={e => dispatch(setDataField({ fieldName: id, fieldValue: e.target.value}))}
            required={true}
            disabled={options?.disabled}
        />)
    }
    {
        !!options?.customComponent && options.customComponent
    }
    {
        !!options?.text && <p className="text-small mt-1 mb-2">{options.text}</p>
    }
  </div>)
}

export const CustomInput = forwardRef(
    (props: any, ref: any) => {
      return <input
                    className="form-control"
                    onClick={props.onClick}
                    placeholder={props.placeholder}
                    ref={ref}
                    value={props.value}
      />
    }
)

export function ScrollToTop() {

  useEffect(() => {
    if (window != undefined) {
      window.scrollTo(0, 0)
    }
  }, []);

  return <></>;
}

export function renderNextButton() {
  return <div className="row mt-4">
    <div className="col-12 d-flex justify-content-center">
      <button className="btn btn-danger mb-5" type="submit">
        Suivant
      </button>
    </div>
  </div>;
}

export const getSubmitTs = () => {
  return window.localStorage.getItem(SUBMIT_KEY)
}