import React, {useEffect} from 'react';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import Loader from 'react-loaders';
import {STEP_1, STEP_2, STEP_3, STORAGE_KEY} from "../utils/constants";
import {setData, setDataField, setDidRead, setSubmitTs} from "../logic/reducer";
import {useDispatch, useSelector} from "react-redux";
import {RootStateI} from "../logic";
import {getSubmitTs, ScrollToTop} from "./helpers";
import Step1 from "./Step1";
import Step3 from "./Step3";
import Step2 from "./Step2";

registerLocale('fr', fr)
setDefaultLocale('fr')

function renderLogo() {
  return <div className="row pt-4 mb-5">
    <div className="col-12 d-flex justify-content-center">
      <img className="twic"
           data-src="image:/cdm/img/relevanc/logo2.png"
           style={{
             width: "70%"
           }}
      />
    </div>
  </div>;
}

function App() {

  const step = useSelector((state: RootStateI) => state.global.step)
  const submitTs = useSelector((state: RootStateI) => state.global.submitTs)
  const isLoading = useSelector((state: RootStateI) => state.global.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    const ts = getSubmitTs()
    if (ts) {
      dispatch(setSubmitTs(ts))
    }

  }, [])

  useEffect(() => {

    try {

      const previousDataStr = window.localStorage.getItem(STORAGE_KEY)

      if (!previousDataStr) {
        return
      }

      const previousData = JSON.parse(previousDataStr)
      dispatch(setData(previousData))

    } catch (err) {
      console.error(err)
    } finally {
      dispatch(setDidRead(true))
    }

  }, [])

  if (!!submitTs) {
    return (<>
      <ScrollToTop/>
      <div className="container">

        {renderLogo()}

        <div className="row mt-4 text-center">
          <div className="col-12">
            <p className="mb-4">Nous avons bien pris en compte votre participation à notre jeu-concours anniversaire.</p>
            <p className="mb-4">Le tirage au sort aura lieu au plus tard le 31 août 2022 parmi l’ensemble des participations valides.</p>
            <p className="mb-4">Casino vous remercie pour votre fidélité !</p>
            <p className="mb-2">
              Participation : le {moment(submitTs, 'X').format("DD-MM-YYYY [à] HH:mm")}
            </p>
          </div>
        </div>
      </div>
    </>)
  }

  return (<>

    {isLoading && (<Loader type="ball-pulse-rise" active={true}/>)}

    <div className="container">
      {renderLogo()}
      <div className="row pt-4">
        <div className="col-12">
          <p>Participez à notre tirage au sort en remplissant ce formulaire.</p>
          <p className="font-weight-bold">Tous les champs sont obligatoires et le ticket de caisse original sera demandé
            lors de la remise du lot.</p>
          <p>Vous pouvez <a
              href="https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/pdf/reglement_jeu_anniversaire_mt.pdf"
              className="font-weight-bold"
              target="_blank"><u>consulter le règlement du jeu.</u></a>
          </p>
          <p>
            * : champs obligatoires
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {step == STEP_1 && <Step1/>}
          {step == STEP_2 && <Step2/>}
          {step == STEP_3 && <Step3/>}
        </div>
      </div>

      {renderEULA()}

      <div style={{height: 50}}>
        &nbsp;
      </div>
    </div>
  </>)
}

export default App;

// @ts-ignore
function renderEULA() {
  return <div className="w-100 d-flex justify-content-center">
    <small>
      <a
          className="d-block"
          href="https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/pdf/reglement_jeu_anniversaire_mt.pdf"
          target="_blank">
        Règlement du jeu
      </a>
    </small>
  </div>;
}