import {renderInput, renderNextButton, renderTitle, ScrollToTop} from "./helpers";
import AsyncSelect from "react-select/async";
import React, {useState} from "react";
import {Datum} from "../model/Datum";
import {setDataField, setStep} from "../logic/reducer";
import {useDispatch, useSelector} from "react-redux";
import DB2 from "./database.json";
import {RootStateI} from "../logic";
import {STEP_3} from "../utils/constants";
//@ts-ignore
const DB: Datum[] = DB2

export default function Step2() {

  const [selectedStore, setSelectedStore] = useState("")

  const storeId = useSelector((state: RootStateI) => state.global.data.storeId)
  const storeName = useSelector((state: RootStateI) => state.global.data.storeName)

  const dispatch = useDispatch()

  function isInFilter(queryLowercase: string, item: Datum) {

    const queryUppercase = queryLowercase.toUpperCase()
    const split = queryUppercase.split(' ')

    let res = false

    split.forEach(queryPart => {
      res = res || !!((item.storeName && item.storeName.toUpperCase().includes(queryPart)) ||
          (item.storeChain && item.storeChain.toUpperCase().includes(queryPart)) ||
          (item.postalCode && (`${item.postalCode}`).toUpperCase().includes(queryPart)) ||
          (item.city && item.city.toUpperCase().includes(queryPart)) ||
          (item.address1 && item.address1.toUpperCase().includes(queryPart)) ||
          (item.address2 && item.address2.toUpperCase().includes(queryPart)) ||
          (item.area && item.area.toUpperCase().includes(queryPart)) ||
          (item.areaCode && (`${item.areaCode}`).toUpperCase().includes(queryPart)))
    })

    return res
  }

  const onInputChange = (selectedOption: any) => {
    dispatch(setDataField({
      fieldName: "storeId",
      fieldValue: selectedOption.value
    }))
    dispatch(setDataField({
      fieldName: "storeName",
      fieldValue: selectedOption.label
    }))
    setSelectedStore(selectedOption.value)
  }

  const loadOptions = (query: string): Promise<any> => {
    return new Promise((resolve, reject) => {

      try {
        const results = DB.filter(item => isInFilter(query, item))
        const map = results.map((result, idx) => {
          const placeTitle = buildPlaceTitle(result)
          return {
            value: result.storeId,
            label: placeTitle
          }
        })
        resolve(map)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })

  };

  const onSubmit = async (nativeEvent: React.FormEvent<HTMLFormElement>) => {

    if (nativeEvent && nativeEvent.preventDefault) {
      nativeEvent.preventDefault()
    }

    if (!storeId || !storeName) {
      alert("Vous devez indiquer le magasin dans lequel vous avez fait l'achat")
      return
    }

    dispatch(setStep(STEP_3))

  }

  return (<form onSubmit={onSubmit}>
    <ScrollToTop/>
    <div className="row">
      <div className="col-12">
        {renderTitle("(2/3) Votre magasin*", !!storeId ? (<i className="fas fa-edit ml-2" onClick={() => {
          dispatch(setDataField({ fieldName: "storeId", fieldValue: undefined}))
          dispatch(setDataField({ fieldName: "storeName", fieldValue: undefined}))
        }
        }/>) : undefined)}
        {/*<p>*/}
        {/*  Utilisez la barre de recherche ci-dessous pour trouver le magasin dans lequel vous avez fait vos achats.*/}
        {/*</p>*/}
        {
          !storeId && (<AsyncSelect
              className="mb-4 no-bg"
              loadOptions={loadOptions}
              // defaultOptions
              placeholder="Saisir une ville/code postal"
              noOptionsMessage={obj => "Pas de résultats"}
              loadingMessage={() => "Chargement..."}
              components={{DropdownIndicator, Option}}
              onChange={onInputChange}
              cacheOptions={false}
              classNamePrefix="react-select"
              isClearable={true}
              value={selectedStore}
          />)
        }

        {
          renderInput("storeName", "text", "Magasin", storeName, dispatch, {
              disabled: true,
              hideLabel: true,
              placeholder: "Sera rempli automatiquement",
              className: !storeId ? "d-none": undefined,
          })
        }

        {renderInput("storeId", "text", "Code magasin", storeId, dispatch, {
          disabled: true,
          placeholder: "Sera rempli automatiquement",
          className: "d-none"
        })}
      </div>
    </div>
    {renderNextButton()}
  </form>);

}

const buildPlaceTitle = (datum: Datum) => {
  const res = `${datum.storeName}`
  return res.replace(/\s+/g, ' ')
}

const buildPlaceSubtitle = (datum: Datum) => {
  const res = `${datum.address1 || ""} ${datum.address2 || ""} ${datum.postalCode || ""} ${datum.city || ""} ${datum.area ? ` ${datum.area}` : ""}`
  return res.replace(/\s+/g, ' ')
}

const DropdownIndicator = (props: any) => {
  return null;
};

const Option = (props: any) => {
  const store = DB.find(datum => datum.storeId === props.data.value)
  // @ts-ignore
  const title = buildPlaceTitle(store)
  // @ts-ignore
  const subTitle = buildPlaceSubtitle(store)
  return <div {...props.innerProps} className={`${(props.innerProps.className || "")} px-2 py-2 search-result `}>
    <span className="d-block font-weight-bold">
      {title}
    </span>
    <span className="d-block">
      <small>{subTitle}</small>
    </span>
  </div>
}