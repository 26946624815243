import {createSlice} from "@reduxjs/toolkit";
import {STEP_1, STORAGE_KEY, SUBMIT_KEY} from "../utils/constants";

interface DataI {
  email?: string
  firstName?: string
  lastName?: string
  dateOfBirth?: Date
  phoneNumber?: string
  address?: string
  postalCode?: string
  storeId?: string
  storeName?: string
  dateOfPurchase?: Date
  receiptNumber?: string
  counterNumber?: string
  amountSpent?: string
  pictureId?: string
  pictureName?: string
}

interface GlobalStateI {
  data: DataI
  percentCompleted?: string
  didRead?: boolean
  isLoading?: boolean
  submitTs?: number
  step: number
}

const initialState: GlobalStateI = {
  data: {},
  step: STEP_1
}

const globalStateSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setDataField: (state, action) => {
      if (!state.data) {
        state.data = {}
      }
      state.data = {
        ...state.data,
        [action.payload.fieldName]: action.payload.fieldValue
      }
      if (state.didRead) {
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.data))
      }
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setDidRead: (state, action) => {
      state.didRead = action.payload
    },
    setPercentCompleted: (state, action) => {
      state.percentCompleted = action.payload
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
    setSubmitTs: (state, action) => {
      if (!action.payload) {
        return
      }
      state.submitTs = action.payload
      window.localStorage.setItem(SUBMIT_KEY, `${(action.payload)}`)
    }
  }
})


export default globalStateSlice.reducer
export const {
  setDataField,
  setIsLoading,
  setDidRead,
  setPercentCompleted,
  setStep,
  setSubmitTs,
  setData
} = globalStateSlice.actions

