export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  //@ts-ignore
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export async function ResizeImage(file: File, width: number = 1080, height: number = 1080): Promise<Blob|null> {
  return new Promise(async (resolve, reject) => {
    try {
      const base64image = await toBase64(file)

      let img = new Image();
      img.src = base64image;

      img.onload = () => {

        // Make sure the width and height preserve the original aspect ratio and adjust if needed
        if (img.height > img.width) {
          width = Math.floor(height * (img.width / img.height));
        } else {
          height = Math.floor(width * (img.height / img.width));
        }

        let resizingCanvas: HTMLCanvasElement = document.createElement('canvas');
        let resizingCanvasContext = resizingCanvas.getContext("2d");
        // Start with original image size
        resizingCanvas.width = img.width;
        resizingCanvas.height = img.height;
        // Draw the original image on the (temp) resizing canvas
        //@ts-ignore
        resizingCanvasContext.drawImage(img, 0, 0, resizingCanvas.width, resizingCanvas.height);

        let curImageDimensions = {
          width: Math.floor(img.width),
          height: Math.floor(img.height)
        };

        let halfImageDimensions: { width: number | null, height: number | null } = {
          width: null,
          height: null
        };

        // Quickly reduce the dize by 50% each time in few itterations until the size is less then
        // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
        // created with direct reduction of very big image to small image
        while (curImageDimensions.width * 0.5 > width) {
          // Reduce the resizing canvas by half and refresh the image
          halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
          halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);
          //@ts-ignore
          resizingCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
              0, 0, halfImageDimensions.width, halfImageDimensions.height);

          curImageDimensions.width = halfImageDimensions.width;
          curImageDimensions.height = halfImageDimensions.height;
        }

        // Now do final resize for the resizingCanvas to meet the dimension requirments
        // directly to the output canvas, that will output the final image
        let outputCanvas: HTMLCanvasElement = document.createElement('canvas');
        //@ts-ignore
        let outputCanvasContext = outputCanvas.getContext("2d");
        outputCanvas.width = width;
        outputCanvas.height = height;

        //@ts-ignore
        outputCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height, 0, 0, width, height);
        // output the canvas pixels as an image. params: format, quality
        //@ts-ignore
        outputCanvas.toBlob(
            (blob: Blob | null) => {
              console.log("resolve")
              resolve(blob)
            },
            "image/jpeg",
            0.85)
      }
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}