import DatePicker from "react-datepicker";
import React, {useState} from "react";
import {CustomInput, isMobile, renderInput, renderNextButton, renderTitle, ScrollToTop} from "./helpers";
import moment from "moment/moment";
import {setDataField, setStep} from "../logic/reducer";
import {MOMENT_FORMAT, STEP_2} from "../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {RootStateI} from "../logic";

export default function Step1() {

  const email = useSelector((state: RootStateI) => state.global.data.email)
  const lastName = useSelector((state: RootStateI) => state.global.data.lastName)
  const firstName = useSelector((state: RootStateI) => state.global.data.firstName)
  const dateOfBirth = useSelector((state: RootStateI) => state.global.data.dateOfBirth)
  const phoneNumber = useSelector((state: RootStateI) => state.global.data.phoneNumber)
  const address = useSelector((state: RootStateI) => state.global.data.address)
  const postalCode = useSelector((state: RootStateI) => state.global.data.postalCode)

  const dispatch = useDispatch()

  function renderPersonalInfo() {
    return <>
      {renderTitle("(1/3) Vos coordonnées")}
      {renderInput("email", "email", "Adresse email", email, dispatch, {
        placeholder: "albert@superemail.fr"
      })}
      {renderInput("lastName", "text", "Nom", lastName, dispatch, {
        placeholder: "Dupont"
      })}
      {renderInput("firstName", "text", "Prénom", firstName, dispatch, {
        placeholder: "Aristide"
      })}
      {renderInput("dateOfBirth", null, "Date de naissance", undefined, dispatch, {
        customComponent: (<DatePicker
            className="form-control"
            selected={dateOfBirth ? moment(dateOfBirth, MOMENT_FORMAT).toDate() : undefined}
            onChange={(date) => {

              const m = moment(date)

              if (m.isValid()) {
                dispatch(setDataField({
                  fieldName: "dateOfBirth",
                  fieldValue: m.format(MOMENT_FORMAT)
                }))
              }

            }}
            locale="fr"
            dateFormat="dd/MM/yyyy"
            // customInput={<CustomInput/>}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="DD/MM/YYYY"
        />)
      })}
      {renderInput("phoneNumber", "text", "Numéro de téléphone", phoneNumber, dispatch, {
        inputMode: 'tel',
        placeholder: "+336.../06..."
      })}
      {renderInput("address", null, "Adresse postale", address, dispatch, {
        placeholder: "Numéro et voie",
        customComponent: <textarea className="form-control" rows={3} value={address}
                                   placeholder={isMobile ? "Adresse postale" : undefined}
                                   onChange={e => dispatch(setDataField({ fieldName: "address", fieldValue: e.target.value}))} required={true}/>
      })}
      {renderInput("postalCode", "numeric", "Code postal", postalCode, dispatch, {
        inputMode: 'numeric',
        placeholder: "75009"
      })}
    </>;
  }

  const onSubmit = async (nativeEvent: React.FormEvent<HTMLFormElement>) => {

    if (nativeEvent && nativeEvent.preventDefault) {
      nativeEvent.preventDefault()
    }

    if (!phoneNumber || !phoneNumber.length || (phoneNumber.length !== 12 && phoneNumber.length !== 10)) {
      alert("Le numéro de téléphone renseigné est incorrect")
      return
    }

    if (!dateOfBirth) {
      alert("Vous devez indiquer votre date de naissance")
      return
    }

    const duration = moment.duration(moment(dateOfBirth).diff(moment()))
    const years = Math.abs(duration.asYears())

    if (years < 18) {
      alert("Vous devez avoir plus de 18 ans pour participer")
      return
    }

    dispatch(setStep(STEP_2))
  }

  return (<form className="" onSubmit={onSubmit}>
    <ScrollToTop/>
    <div className="row">
      <div className="col-12">
        {renderPersonalInfo()}
      </div>
    </div>
    {renderNextButton()}
  </form>)
}