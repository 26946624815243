import React, {useEffect} from "react";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import {RootStateI} from "../logic";
import {renderInput, renderTitle} from "./helpers";
import DatePicker from "react-datepicker";
import {setDataField, setIsLoading, setPercentCompleted, setSubmitTs} from "../logic/reducer";
import {INPUT_ID, MOMENT_FORMAT, SIGN_HEIGHT, SIGN_WIDTH, STORAGE_KEY} from "../utils/constants";
import axios from "axios";
import TSConfig from "../model/TSConfig";
import {ResizeImage} from "../utils/ImageTools";

export default function Step3() {

  const data = useSelector((state: RootStateI) => state.global.data)
  const percentCompleted = useSelector((state: RootStateI) => state.global.percentCompleted)

  const dispatch = useDispatch()

  const onSubmit = async (nativeEvent: React.FormEvent<HTMLFormElement>) => {

    if (nativeEvent && nativeEvent.preventDefault) {
      nativeEvent.preventDefault()
    }

    if (!data.dateOfPurchase) {
      alert("Vous devez indiquer la date de l'achat")
      return
    }

    if (!data.pictureId) {
      alert("Vous devez télécharger une photo de votre ticket de caisse")
      return
    }

    try {

      dispatch(setIsLoading(true))

      const res = await axios({
        url: `${TSConfig.BACKEND_URL}/adv_forms`,
        method: 'post',
        params: {
          owner: TSConfig.OWNER,
          phoneNumber: data.phoneNumber,
        },
        data
      })
      window.localStorage.removeItem(STORAGE_KEY)
      dispatch(setSubmitTs(res.data))

    } catch (err) {
      //@ts-ignore
      if (err && err.response && err.response.status === 403) {
        //@ts-ignore
        dispatch(setSubmitTs(err.response.data))
      } else {
        alert("Erreur")
      }
    } finally {
      dispatch(setIsLoading(false))
    }

  }

  const onPressCamera = () => {
    const input = document.getElementById(INPUT_ID);
    if (input) {
      input.click();
    }
  }

  const shrinkImageIfNeeded = async (file: File) => {

    if (file.name.toUpperCase().includes(".PDF")) {
      return file
    }

    try {

      const blob = await ResizeImage(file)

      if (!blob) {
        alert("Erreur (2)")
        return file
      }

      return new File([blob], file.name)

    } catch (err) {
      alert("Erreur (3)")
      console.error(err)
    }

    return file
  }

  const onPictureChosen = async (e: any) => {

    if (!e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];

    if (!file) {
      alert("Erreur (1)")
      return
    }

    try {

      dispatch(setIsLoading(true))
      dispatch(setPercentCompleted(null))

      const finalFile = await shrinkImageIfNeeded(file)
      const formData = new FormData()
      formData.append('file', finalFile)

      const res = await axios({
        url: `${TSConfig.BACKEND_URL}/adv_forms/upload`,
        method: 'post',
        params: {
          path: "kadow/receipts/relevanc",
          name: file.name
        },
        data: formData,
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          dispatch(setPercentCompleted(percentCompleted))
        }
      })

      dispatch(setDataField({
        fieldName: "pictureId",
        fieldValue: res.data
      }))
      dispatch(setDataField({
        fieldName: "pictureName",
        fieldValue: file.name
      }))

    } catch (err) {
      console.error(err)
      alert("Erreur (4)")
    } finally {
      dispatch(setIsLoading(false))
      dispatch(setPercentCompleted(null))
    }
  }

  useEffect(() => {
    const fileInput = document.getElementById(INPUT_ID);

    if (fileInput) {
      fileInput.addEventListener("change", onPictureChosen);
    }

  }, [])

  return (<>
    <form onSubmit={onSubmit}>
      {renderTitle("(3/3) Vos achats")}
      {renderInput("dateOfPurchase", null, "Date d'achat", undefined, dispatch, {
        customComponent: (<DatePicker
            className="form-control"
            selected={data.dateOfPurchase ? moment(data.dateOfPurchase, MOMENT_FORMAT).toDate() : undefined}
            onChange={(date) => {

              const m = moment(date)

              if (m.isValid()) {
                dispatch(setDataField({
                  fieldName: "dateOfPurchase",
                  fieldValue: m.format(MOMENT_FORMAT)
                }))
              }

            }}
            locale="fr"
            dateFormat="dd/MM/yyyy"
            // customInput={<CustomInput/>}
            placeholderText="DD/MM/YYYY"
        />)
      })}
      {renderInput("receiptNumber", "number", "N° de ticket de caisse", data.receiptNumber, dispatch, {
        inputMode: "numeric",
        text: "(A retrouver en bas de votre ticket papier ou e-ticket CasinoMax)"
      })}
      {renderInput("counterNumber", "number", "N° de caisse", data.counterNumber, dispatch, {
        inputMode: "numeric",
        text: "(A retrouver en bas de votre ticket papier ou e-ticket CasinoMax)"
      })}
      {renderInput("amountSpent", "number", "Montant dépensé (€)", data.amountSpent, dispatch, {
        inputMode: "decimal",
        text: "(A retrouver en bas de votre ticket papier ou e-ticket CasinoMax)"
      })}

      {renderTitle("Votre preuve d'achat*")}
      {!data.pictureId && (<p>
        Merci de télécharger votre ticket de caisse. Formats acceptés : tous formats d'image et PDF. Taille maximale :
        20 Mo.
      </p>)}
      {!!data.pictureId && (<p>
        Photo bien reçue ! Si vous souhaitez la modifier, cliquez simplement sur la photo existante.
      </p>)}
      <div className="mb-5 d-flex justify-content-center">

        {
            !data.pictureId && (<div className="">
              <button className="btn btn-danger" type="button" onClick={() => document.getElementById(INPUT_ID)?.click()}>
                Télécharger la preuve d'achat{percentCompleted ? ` ${percentCompleted}%` : ""}
              </button>
            </div>)
        }

        {!!data.pictureId && (<div className="d-flex justify-content-center" onClick={onPressCamera}>
          <div className="d-flex justify-content-center align-items-center"
               style={{
                 width: SIGN_WIDTH,
                 height: SIGN_HEIGHT,
                 border: "1px solid black"
               }}>
            {
              data.pictureName?.includes("pdf") && (<span className="w-100 h-100 text-center pt-5 font-weight-bold">
                  <i className="fas fa-file-pdf" style={{fontSize: 40}}/>
                </span>)
            }
            {
                !data.pictureName?.includes("pdf") && (<img className="twic"
                                                                        data-src={`image:/cdm/${data.pictureId}`}
                                                                        data-src-transform="cover=WxH"
                                                                        style={{
                                                                          width: SIGN_WIDTH,
                                                                          height: SIGN_HEIGHT
                                                                        }}
                />)
            }
            <i className="fas fa-check" style={{
              position: "absolute",
              color: "limegreen",
              fontSize: 40
            }}/>
          </div>
        </div>)}

      </div>

      {
         !!data.pictureId && (<div className="row">
            <div className="col-12 d-flex justify-content-center">
              <button className="btn btn-danger mb-5" type="submit">
                Valider la participation
              </button>
            </div>
          </div>)
      }

    </form>

    <input
        id={INPUT_ID}
        type="file"
        accept="image/*,application/pdf"
        style={{display: "none"}}
    />
  </>);
}